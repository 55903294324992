<template>
  <div>
    <el-dialog :visible.sync="dialogVisible" width="60%" :before-close="handleClose">
      <template slot="title">
        <div class="plugin-modal-header">
          <div class="plugin-info">
            <div class="plugin-img">
              <img :src="jiraIcon" alt="" />
            </div>
            <div class="plugin-name">
              <p>Jira</p>
              <span>Altassian</span>
            </div>
          </div>
        </div>
      </template>
      <div class="plugin-modal-body">
        <p>You're about to enable Jira</p>
        <div class="plugin-modal-login">
          <div>
            <p><b>Email</b></p>
          </div>
          <div style="margin-bottom: 10px">
            <el-input placeholder="Enter your Jira email..." v-model="tempConfig.email" />
          </div>
        </div>
        <div class="plugin-modal-configuration">
          <div>
            <p><b>API Key</b></p>
          </div>
          <div>
            <el-input
              type="password"
              placeholder="Enter your Jira API key..."
              v-model="tempConfig.apiKey"
            />
          </div>
        </div>
        <div class="plugin-modal-configuration">
          <div>
            <p><b>Base URL</b></p>
          </div>
          <div>
            <el-input
              placeholder='Enter the server url, i.e. "https://<your-domain>.atlassian.net/rest/api/latest/"'
              v-model="tempConfig.url"
            />
          </div>
        </div>
        <div class="plugin-modal-configuration">
          <div>
            <p><b>Configure JSON file</b></p>
          </div>
          <div>
            <PluginUpload @updateFileUrl="handleUpdateFileUrl" :selectedPlugin="selectedPlugin" />

            <div v-if="tempConfig.fileUrl">
              <div>
                <p><b>JSON file URL</b></p>
              </div>
              <el-input v-model="tempConfig.fileUrl" disabled />
            </div>
          </div>
        </div>
      </div>
      <template slot="footer">
        <div>
          <el-button type="plain" @click="handleClose">Cancel</el-button>
          <el-button type="primary" @click="handleConfirm">Confirm</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import jiraIcon from "./assets/icons/jira.png";
import PluginUpload from "./PluginUpload.vue";

export default {
  components: {
    PluginUpload,
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    selectedPlugin: {
      type: Object,
    },
  },
  data() {
    return {
      jiraIcon,
      tempConfig: {
        email: "",
        apiKey: "",
        url: "",
        fileUrl: "",
      },
      isUploading: false,
    };
  },
  methods: {
    handleClose() {
      this.$confirm("Do you want to close this window?", "Warning", {
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        type: "warning",
      })
        .then(() => {
          this.$emit("updateDialogVisible", false);
        })
        .catch(() => {});
    },
    handleConfirm() {
      this.$confirm("Do you want to save the current settings ?", "Warning", {
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        type: "warning",
      }).then(() => {
        this.$emit("updatePluginConfig", this.tempConfig);
      });
    },
    handleUpdateFileUrl(fileUrl) {
      this.tempConfig.fileUrl = fileUrl;
      this.$forceUpdate();
    },
  },
  created() {
    if (this.selectedPlugin) {
      this.tempConfig = this.selectedPlugin.config;
    }
  },
};
</script>

<style lang="scss">
@import "./assets/css/modal.scss";

.upload-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 180px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  color: #8c939d;
  cursor: pointer;
  transition: border-color 0.1s;

  &:hover {
    border-color: #409eff;
  }
}
</style>
