<template>
  <div class="website-wrapper">
    <div class="website-header">
      <div class="main-title">Websites</div>
      <div style="display: flex; gap: 10px">
        <el-input
          v-model="search"
          placeholder="Search website"
          prefix-icon="el-icon-search"
          clearable
          @clear="handleClearSearch"
          @input="handleSearch"
        ></el-input>
        <el-button type="primary" round @click="addWebsiteDialogVisible = true"
          >Add new website</el-button
        >
      </div>
    </div>
    <div class="website-body">
      <el-table :data="websites" v-loading="isLoading">
        <el-table-column type="index"></el-table-column>
        <el-table-column prop="title" label="Title">
          <template slot-scope="scope">
            <span style="margin-right: 10px">{{ scope.row.title }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="url" label="Url"></el-table-column>
        <el-table-column prop="tags" label="Tags">
          <template slot-scope="scope">
            <div>
              <div style="margin-bottom: 15px" v-for="(tags, name) in scope.row.tags" :key="name">
                <span>{{ name }} : </span>
                <el-tag v-for="(tag, index) in tags" :key="index" style="margin-right: 10px">{{
                  tag
                }}</el-tag>
              </div>
              <el-button size="mini" type="primary" @click="handleOpenTagEditor(scope.row)">
                <i class="el-icon-edit"></i>
              </el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Actions" width="500">
          <template slot-scope="scope">
            <div class="table-wrapper">
              <el-button
                type="primary"
                size="small"
                style="margin-right: 10px"
                @click="openStatusDialog(scope.row)"
                :loading="isFetchDocumentStatus"
                :disabled="isFetchDocumentStatus"
                >Status</el-button
              >
              <el-button
                v-if="scope.row.content?.length > 0"
                icon="el-icon-upload"
                @click="handleUploadWebsite(scope.row.id)"
                size="small"
                type="primary"
                >Upload</el-button
              >
              <el-button
                icon="el-icon-edit"
                s
                @click="handleEditWebsite(scope.row)"
                size="small"
                type="warning"
                >Edit</el-button
              >
              <!-- <el-button icon="el-icon-plus" circle type="primary"></el-button> -->
              <el-button
                icon="el-icon-delete"
                size="small"
                type="danger"
                @click="handleDeleteWebsite(scope.row.id)"
                >Delete</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="website-footer" v-if="websitesLength > 0 && !inSearchMode">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="websitesLength"
        :page-size="5"
        :pager-count="5"
        @current-change="handleCurrentPageChange"
        :currentPage.sync="currentPage"
      ></el-pagination>
    </div>
    <Editor
      v-if="editorVisible"
      :website="selectedWebsite"
      :editorVisible="editorVisible"
      @updateEditorVisible="updateEditorVisible"
      @handleUpdatedWebsite="handleUpdatedWebsite"
      :isEditorDialogLoading="isEditorDialogLoading"
    />
    <AddWebsite
      v-if="addWebsiteDialogVisible"
      :addWebsiteDialogVisible="addWebsiteDialogVisible"
      @updateEditorVisible="updateEditorVisible"
      @handleCreateWebsite="handleCreateWebsite"
      :isWebsiteDialogLoading="isWebsiteDialogLoading"
    />
    <DocumentStatusModal
      v-if="statusDialogVisible && isSelectedDocumentUpload"
      :statusDialogVisible="statusDialogVisible"
      @updateStatusDialogVisible="updateStatusDialogVisible"
      @refreshDocumentStatus="refreshDocumentStatus"
      :selectedDocumentStatus="selectedDocumentStatus"
      :isRefreshingStatus="isRefreshingStatus"
      :isWebsite="true"
      :selectedDocument="selectedWebsite"
    />
    <document-status-not-upload-modal
      v-else-if="statusDialogVisible && !isSelectedDocumentUpload"
      @updateStatusDialogVisible="updateStatusDialogVisible"
      :statusDialogVisible="statusDialogVisible"
      :isWebsite="true"
      :selectedWebsite="selectedWebsite"
    />

    <TagEditor
      :shouldShowTagEditor="shouldShowTagEditor"
      :selectedWebsite="selectedWebsite"
      :isLoading="isLoading"
      @handleCloseTagEditor="handleCloseTagEditor"
      @handleUpdateWebsiteTag="handleUpdateWebsiteTag"
    />
  </div>
</template>

<script>
import Editor from "./Editor.vue";
import AddWebsite from "./AddWebsite.vue";
import DocumentStatusModal from "../Documents/DocumentStatusModal.vue";
import DocumentStatusNotUploadModal from "../Documents/DocumentStatusNotUploadModal.vue";
import TagEditor from "./TagEditor.vue";

export default {
  components: {
    Editor,
    AddWebsite,
    DocumentStatusModal,
    DocumentStatusNotUploadModal,
    TagEditor,
  },
  data() {
    return {
      editorVisible: false,
      addWebsiteDialogVisible: false,
      selectedWebsite: {},
      websitesLength: 0,
      isWebsiteDialogLoading: false,
      isEditorDialogLoading: false,
      isLoading: false,
      selectedDocumentStatus: null,
      isFetchDocumentStatus: false,
      isRefreshingStatus: false,
      statusDialogVisible: false,
      isSelectedDocumentUpload: false,
      shouldShowTagEditor: false,
      currentPage: 1,
      search: "",
      inSearchMode: false,
    };
  },
  computed: {
    websites() {
      const websites = _.cloneDeep(this.$store.state.genai.genai_websites);
      websites.forEach((website) => {
        if (website.tags) {
          website.tags = JSON.parse(website.tags);
        } else {
          website.tags = {};
        }
      });
      return websites;
    },
    userProfile() {
      return this.$store.state.profile;
    },
  },
  methods: {
    handleEditWebsite(website) {
      this.selectedWebsite = website;
      this.editorVisible = true;
    },
    updateEditorVisible({ dialog, status }) {
      switch (dialog) {
        case "editor":
          this.editorVisible = status;
          break;
        case "addWebsite":
          this.addWebsiteDialogVisible = status;
          break;
        default:
          break;
      }
    },
    async handleCreateWebsite(website) {
      this.isWebsiteDialogLoading = true;
      const result = await this.$store.dispatch("GENAI_CREATE_WEBSITE", website);
      this.isWebsiteDialogLoading = false;
      this.addWebsiteDialogVisible = false;
      if (result.success) {
        this.$message({
          type: "success",
          message: "Create website successfully!",
        });
      } else {
        this.$message({
          type: "error",
          message: "Failed to create website",
        });
      }
    },
    async handleUploadWebsite(id) {
      this.$confirm("Are you sure to upload this website?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(async () => {
          this.isLoading = true;
          const uploadedBy = this.userProfile.email;
          const result = await this.$store.dispatch("GENAI_UPLOAD_WEBSITE", {
            id,
            uploadedBy,
            page: this.currentPage,
          });
          if (result.success) {
            this.$message({
              type: "success",
              message: "Upload successfully!",
            });
          }
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.$notify({
            title: "Error",
            message: "There was an error uploading the website",
            type: "error",
          });
        });
    },
    handleDeleteWebsite(id) {
      this.$confirm("Are you sure to delete this website?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(async () => {
          this.isLoading = true;
          const result = await this.$store.dispatch("GENAI_DELETE_WEBSITE", { id });
          if (result.success) {
            this.$message({
              type: "success",
              message: "Delete successfully!",
            });
          }
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.$notify({
            title: "Error",
            message: "There was an error deleting the article",
            type: "error",
          });
        });
    },
    async handleUpdatedWebsite(newWebsite) {
      this.isLoading = true;
      this.isEditorDialogLoading = true;
      const result = await this.$store.dispatch("GENAI_UPDATE_WEBSITE", {
        newWebsite,
        page: this.currentPage,
      });
      if (result.success) {
        this.isEditorDialogLoading = false;
        this.editorVisible = false;
        this.isLoading = false;
        this.$message({
          type: "success",
          message: "Update successfully!",
        });
      } else {
        this.isLoading = false;
        this.isEditorDialogLoading = false;
        this.$notify({
          title: "Error",
          message: "There was an error updating the article",
          type: "error",
        });
      }
    },
    async handleCurrentPageChange(page) {
      this.isLoading = true;
      await this.$store.dispatch("GENAI_GET_WEBSITES", { page });
      this.isLoading = false;
    },
    updateStatusDialogVisible(value) {
      this.statusDialogVisible = value;
    },
    async openStatusDialog(website) {
      try {
        this.isLoading = true;
        if (website.isUploaded) {
          const documentStatus = await this.$store.dispatch("GENAI_GET_DOCUMENT_STATUS", {
            documentId: website.id,
          });
          this.selectedDocumentStatus = documentStatus;
        }
        this.isSelectedDocumentUpload = website.isUploaded;
        this.selectedWebsite = website;
        this.statusDialogVisible = true;
        this.isLoading = false;
      } catch (error) {
        this.$notify({
          title: "Error",
          message: "There was an error getting the document status",
          type: "error",
        });
        this.isLoading = false;
      }
    },
    async refreshDocumentStatus(documentId) {
      this.isRefreshingStatus = true;
      const documentStatus = await this.$store.dispatch("GENAI_GET_DOCUMENT_STATUS", {
        documentId,
      });
      this.selectedDocumentStatus = documentStatus;
      this.isRefreshingStatus = false;
      return documentStatus;
    },
    handleCloseTagEditor() {
      this.shouldShowTagEditor = false;
    },
    handleOpenTagEditor(website) {
      this.shouldShowTagEditor = true;
      this.selectedWebsite = website;
    },
    async handleUpdateWebsiteTag({ newWebsite, oldWebsite }) {
      this.isLoading = true;
      const oldWebsiteId = oldWebsite.id;
      if (newWebsite.tags) {
        newWebsite.tags = JSON.stringify(newWebsite.tags);
      }
      const result = await this.$store.dispatch("GENAI_CREATE_WEBSITE", newWebsite);
      if (result.success) {
        await this.$store.dispatch("GENAI_DELETE_WEBSITE", { id: oldWebsiteId });
        this.shouldShowTagEditor = false;
        this.isLoading = false;
        this.$message({
          type: "success",
          message: "Update article successfully!",
        });
        this.currentPage = 1;
        this.$store.dispatch("GENAI_GET_WEBSITES", { page: 1 });
      } else {
        this.$message({
          type: "error",
          message: "Failed to update article",
        });
        this.isLoading = false;
      }
    },
    handleSearch: _.debounce(async function () {
      try {
        this.isLoading = true;
        if (this.search === "") {
          await this.$store.dispatch("GENAI_GET_WEBSITES", { page: 1 });
          this.inSearchMode = false;
          this.isLoading = false;
          return;
        }
        await this.$store.dispatch("GENAI_SEARCH_DOCUMENTS", {
          search: this.search,
          documentType: "websites",
        });
        this.isLoading = false;
        this.inSearchMode = true;
      } catch (error) {
        this.inSearchMode = false;
        this.isLoading = false;
        this.$notify({
          title: "Error",
          message: "There was an error searching the website",
          type: "error",
        });
      }
    }, 800),

    async handleClearSearch() {
      try {
        this.search = "";
        await this.$store.dispatch("GENAI_GET_WEBSITES", { page: 1 });
        this.isLoading = false;
        this.inSearchMode = false;
      } catch (error) {
        this.isLoading = false;
        this.$notify({
          title: "Error",
          message: "There was an error clearing the search",
          type: "error",
        });
      }
    },
  },
  async created() {
    this.isLoading = true;
    const websites = await this.$store.dispatch("GENAI_GET_WEBSITES", { page: 1 });
    this.websitesLength = websites.count;
    this.isLoading = false;
  },
};
</script>

<style lang="scss">
.website-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.website-body {
  padding: 20px;
}

.website-wrapper {
  margin: 20px;
}

.table-wrapper {
  display: flex;
  gap: 15px;
  flex-wrap: no-wrap;

  button {
    margin: 0 !important;
  }
}
</style>
