<template>
  <div class="livechat-container">
    <div class="livechat-container--top">
      <SearchChatFilters @resetAbandonedChatFilters="reset" tab="abandoned" />
    </div>

    <div class="livechat-container--left" ref="sidebar">
      <div class="chat-list--header">
        <span
          >{{ abandonedChatsArray?.length || 0 }} of {{ abandonedBadgeNumber || 0 }} Abandoned
          Chats</span
        >
      </div>
      <div class="chat-list--wrapper">
        <div class="chat-list--main">
          <AbandonedChatList
            @fetchMoreAbandonedChats="fetchMoreAbandonedChats"
            :selected-index.sync="selectedIndex"
            :show-more-is-loading="showMoreIsLoading"
            :has-more-chats="hasMoreChats"
            @on-init-fetch-interaction="onInitFetchInteraction"
          />
        </div>
      </div>
      <div class="livechat-resizer" ref="resizer"></div>
    </div>
    <div class="livechat-container--right">
      <ChatsInteractions
        v-if="selectedChat"
        ref="interactionsPanel"
        :chats-list="abandonedChatsArray"
        :selected-chat="selectedChat"
        :selected-index.sync="selectedIndex"
        :is-loading="fetchAbandonedChatLoading"
        :closed.sync="closed"
      />
      <el-row v-if="$store.state.showAdvanced">
        <JSONEditor v-model="selectedChat" />
      </el-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getAvatar } from "@/helperMethods/livechat/util";
import SearchChatFilters from "./Filter.vue";
import AbandonedChatList from "./List.vue";
import ChatsInteractions from "@/components/ChatInteractions/Index";
import JSONEditor from "@/components/JSONEditor";
import FETCH_RESOLVED_CHATS_QUERY from "@/graphql/LIVECHATS_GET_RESOLVED.gql";
import COUNT_RESOLVED_CHATS_QUERY from "@/graphql/LIVECHATS_COUNT_RESOLVED.gql";
import _ from "lodash";
import { splitAbandonedResolvedChats } from "@/helperMethods/livechat/splitAbandonedResolvedChats";

export default {
  name: "AbandonedChatsTab",
  components: {
    SearchChatFilters,
    AbandonedChatList,
    ChatsInteractions,
    JSONEditor,
  },
  data() {
    return {
      selectedIndex: 0,
      closed: false,
      type: { name: "abandoned" },
      hasMoreChats: true,
      showMoreIsLoading: false,
    };
  },

  computed: {
    ...mapGetters([
      "fetchAbandonedChatLoading",
      "abandonedChatsArray",
      "unfilteredAbandonedChatsArray",
      "abandonedChatFilters",
      "abandonedBadgeNumber",
      "selectedChatId",
      "selectedChat",
    ]),
  },
  methods: {
    getAvatar,
    reset() {
      this.$store.commit("FLUSH_ABANDONED_CHATS_ARRAY");
      this.hasMoreChats = true;
    },
    fetchMoreAbandonedChats() {
      this.showMoreIsLoading = true;
      const filters = _.cloneDeep(this.abandonedChatFilters);
      filters.offset = this.unfilteredAbandonedChatsArray.length;
      this.$store.commit("UPDATE_ABANDONED_LIVECHAT_FILTERS", filters);
    },
    onInitFetchInteraction() {
      this.$nextTick(() => {
        this.$refs.interactionsPanel.showMoreConversation(this.selectedChat, true);
      });
    },
  },
  apollo: {
    resolvedChats: {
      query: FETCH_RESOLVED_CHATS_QUERY,
      variables() {
        return this.abandonedChatFilters;
      },
      fetchPolicy: "no-cache",
      update(result) {
        const endedSessions = _.get(result, "livechatAPI.resolvedSessions", []);

        if (endedSessions.length < this.abandonedChatFilters.limit) {
          this.hasMoreChats = false;
        }

        // @todo: do we need to filter on front end as it is already filtered on backend?
        const { resolvedChats, abandonedChats } = splitAbandonedResolvedChats(endedSessions);
        this.$store.commit("SET_ABANDONED_CHATS_ARRAY", {
          abandonedChatsArray: abandonedChats,
          unfilteredAbandonedChatsArray: endedSessions,
        });
        this.$store.commit("SET_FETCH_ABANDONED_CHAT_LOADING", false);
        this.showMoreIsLoading = false;
      },
    },
    countResolvedChats: {
      query: COUNT_RESOLVED_CHATS_QUERY,
      variables() {
        return this.abandonedChatFilters;
      },
      fetchPolicy: "network-only",
      update(result) {
        const count = _.get(result, "livechatAPI.countResolvedSessions", 0);
        this.$store.commit("SET_ABANDONED_CHAT_COUNT", { count });
      },
    },
  },
  mounted() {
    const resizer = this.$refs.resizer;
    const sidebar = this.$refs.sidebar;
    this.$emit("initResizer", resizer, sidebar);
  },
};
</script>
